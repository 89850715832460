h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Playfair Display", serif !important;
}
.img_home {
  margin: 19px;
  width: 96%;
  height: 146px;
}
.nav_size {
  width: fit-content;
}
:root {
  --font-size: 16px;
}
body {
  margin: 0;
  padding: 0;
  font-size: var(--font-size);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(/src/Components/Image/petn.jpg);
  background-repeat: repeat-y;
  background-size: 100%;
  background-attachment: fixed;
  background-position: center;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.App {
  text-align: center;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}
.ize_class {
  margin-left: 17px;
}
.btn_eng {
  margin-left: 20px;
}
.btn_hindi {
  margin-left: 20px;
}
.App-link {
  color: #61dafb;
}
.form-control {
  width: 100% !important;
  display: block;
  padding: 0.375rem 0.75rem;
  /* font-size: 1rem; */
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.goog-te-combo {
  display: block;
  width: 89% !important;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.nav_mn {
  padding: 0 50px !important;
}
.nv_rit {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  float: right;
}
.nv_rit a {
  font-weight: 600;
  margin-top: 37px;
  padding: 0px 13px;
}
.banner_mn {
  width: 100%;
  float: left;
  margin-top: 0px;
  margin-top: 157px;
}
.banner_mn img {
  width: 100%;
}
.banner_mn h1 {
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  color: transparent;
  letter-spacing: 0;
  line-height: 105.1px;
  color: #fff;
}
.banner_mn h1 span {
  color: #b62d20;
}
.banner_mn p {
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  color: #ffffff;
  letter-spacing: 0;
  line-height: normal;
}
.banner_mn a {
  background: #b62d20;
  color: #fff;
  font-family: "Segoe UI-Semibold", Helvetica;
  font-weight: 400;
  padding: 15px 24px;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 40px;
  display: inline-block;
}
.tex_wd {
  width: 55%;
  margin-top: 100px;
}
.logo_pic img {
  width: 60%;
  margin: 0 auto;
  border: solid 1px #3f3f3f;
  border-radius: 50%;
}
.notes_mn {
  width: 100%;
  float: left;
  background: #b62d20;
  padding: 60px;
  text-align: center;
  background: url(/src/Components/Image/bg4.jpg) left top no-repeat;
  background-size: cover;
}
.notes_mn p {
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  color: #ffffff;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0;
  line-height: 30.7px;
  padding: 0 50px;
}
.notes_mn p span {
  font-weight: 500;
  font-style: italic;
  text-decoration: underline;
}
.karauli_maharaj_mn {
  width: 100%;
  float: left;
  padding: 70px 0;
  text-align: center;
  background: url(/src/Components/Image/top_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 121px;
}
.karauli_maharaj_mn h2 {
  font-family: "Segoe UI-Semibold", Helvetica;
  font-weight: bold;
  color: #fff;
  text-align: center;
  line-height: normal;
}
.karauli_maharaj_mn p {
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  color: #fff;
  text-align: center;
  line-height: 32px;
  padding: 0 7%;
  padding-top: 14px;
}
.line {
  height: 4px;
  border: solid 4px #b62d20;
  opacity: inherit;
  background: #b62d20;
  width: 12%;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 20px;
}
.ashrm_mn {
  width: 100%;
  float: left;
  padding: 40px 0;
  text-align: center;
  background: url(/src/Components/Image/petn.jpg);
  background-repeat: repeat-y;
  background-size: 100%;
  background-attachment: fixed;
  background-position: center;
}
.ashrm_mn h2 {
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  color: #b62d20 !important;
  /* font-size: 42px; */
  letter-spacing: 0;
  margin: 0;
}

.ashrm_mn p {
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  color: #000;
  letter-spacing: 0;
  line-height: normal;
  padding: 0 18%;
  line-height: 40px;
}
.box1 {
  border: 1px solid #b6b5b5;
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 40px 0;
  width: 90%;
  margin: 0 auto;
  background: #fff;
}
.about_mn {
  width: 100%;
  float: left;
  padding: 0px 0 70px 0;
  background: url(/src/Components/Image/petn.jpg);
  background-repeat: repeat-y;
  background-size: 100%;
  background-attachment: fixed;
  background-position: center;
}
.about_section {
  width: 100%;
  float: left;
  padding: 40px 10px;
  border-top: solid 1px #d8d5d5;
}
.about_pic {
  border-radius: 15px;
}
.about_pic img {
  width: 100%;
  border-radius: 15px;
  box-shadow: rgb(182 45 32) 0px 4px 12px;
  border: 1px solid #b6b5b5;
}
.about_tx p {
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  color: #000000;
  line-height: 36px;
  padding-top: 0;
}
.hit1 {
  height: 700px;
}
.about_tx2P {
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  color: #000000;
  line-height: 36px;
  padding-top: 20px;
}
.contact_mn {
  width: 100%;
  float: left;
  padding: 60px 0;
  background: url(/src/Components/Image/bg1.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  height: 670px;
  background-attachment: fixed;
  background-position: center;
}
.contact_mn1 {
  width: 100%;
  float: left;
  padding: 20px 0 40px 0;
  background: url(/src/Components/Image/bg_fm.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-attachment: fixed;
  background-position: center;
}
.form_mn {
  width: 82%;
  margin: 0 auto;
}
.fom_mes {
  width: 100% !important;
  border-radius: 15px !important;
  height: 102px;
  box-shadow: 0 1px 5px #d8d6d659;
  padding: 15px !important;
}
.fom_stl {
  width: 100% !important;
  border-radius: 15px !important;
  margin-top: 15px;
  box-shadow: 0 1px 5px #d8d6d659;
  height: 44px;
}
.size_class {
  padding: 14px 8px;
  padding-top: 0 !important;
  line-height: 0;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  height: 30px;
  border-radius: 5px;
  outline: none !important;
  margin-right: 10px !important;
 margin-top: 34px !important;
  background: #b62d20;
  color: #fff;
  border: none !important;
}
.VIpgJd-ZVi9od-l4eHX-hSRGPd {
  display: none !important;
}
.goog-te-gadget .goog-te-combo {
  margin: 0 !important;
}
.form_mn h3 {
  color: #fff;
  margin-bottom: 25px;
  font-size: 30px;
}
.read2 {
  background: #b62d20;
  color: #fff;
  outline: none;
  display: block;
  border: none;
  padding: 15px 36px;
  border-radius: 15px;
  width: 21% !important;
  margin: 0 auto;
  margin-top: 40px !important;
  font-size: 18px;
}
.footer_mn {
  width: 100%;
  float: left;
  padding: 60px 0;
  background: url(/src/Components/Image/footer_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.footer_logo {
}
.footer_logo img {
}
.footer_tx {
}
.footer_tx h3 {
  color: #fff;
  line-height: 30px;
  font-size: 24px;
}
.footer_tx2 {
  padding-left: 92px !important;
}
.footer_tx2 h3 {
  color: #fff;
  line-height: 30px;
  font-size: 24px;
  text-transform: uppercase;
}
.footer_tx2 ul {
  margin: 0;
  padding: 0;
}
.footer_tx2 ul li {
  list-style: none;
  line-height: 30px;
}
.footer_tx2 ul li a {
  list-style: none;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
}
.footer_tx p {
  font-size: 16px;
  color: #fff;
}
.line2 {
  height: 2px;
  border: solid 3px #b62d20;
  opacity: inherit;
  background: #b62d20;
  width: 15%;
  margin-bottom: 20px;
  margin-top: 9px;
}
.footer_tx ul {
  padding: 0;
  margin: 0;
}
.footer_tx li {
  list-style: none;
  display: inline-block;
  margin: 0 17px 0 0;
  margin-top: 10px;
}
.footer_tx li img {
  width: 32px;
}
.tample_mn {
  width: 100%;
  float: left;
  padding: 70px 0 60px 0;
  background: url(/src/Components/Image/bg2.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.tample_box {
  width: 100%;
  float: left;
  margin-bottom: 90px;
}
.tample_size {
  background: #fff;
  text-align: center;
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  min-height: 558px;
  position: relative;
}
.tample_size img {
  width: 100%;
  border-radius: 10px;
}
.tample_size h4 {
  color: #b62d20;
  font-weight: 600;
  padding: 10px 10px;
}
.tample_size p {
  font-size: 17px;
  padding: 0px 10px;
  margin-bottom: 30px;
}
.tample_size a {
  background: #b62d20;
  color: #fff;
  padding: 8px 30px;
  position: absolute;
  bottom: 0;
  text-align: center;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}
.tample_size a:hover {
  background: #000;
  color: #fff;
}
.sid_tx {
  font-size: 22px;
  background: #b62d20;
  color: #fff !important;
  padding: 60px !important;
  border-radius: 15px;
}
.Donate_btn {
  background: #b62d20;
  color: #fff;
  padding: 13px 47px;
  margin-bottom: 22px;
  font-size: 20px;
  text-decoration: none;
  border-radius: 10px;
  display: table;
  width: 83%;
  text-align: center;
}
.coprt_mn {
  width: 100%;
  float: left;
  background: #b62d20;
  padding: 10px;
}
.coprt_mn p {
  color: #fff;
  text-align: center;
  padding: 0;
  margin: 0;
}
.about_tx h3 {
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  color: #b62d20 !important;
  letter-spacing: 0;
  margin-bottom: 15px;
}
.nav_header1 {
}
.nav_header1 a {
  text-transform: uppercase;
  text-align: center;
  color: #000;
}
.logo_pic a {
  margin-top: 0 !important;
}
.header_fix1 {
  position: fixed !important;
  z-index: 9;
  width: 100%;
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
    margin: 0 auto !important;
  }
}
.information_mn {
  width: 100%;
  float: left;
  padding: 25px 0;
  background: url(/src/Components/Image/petn.jpg);
  background-repeat: repeat-y;
  background-size: 100%;
  background-attachment: fixed;
  background-position: center;
}
.information_mn h2 {
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  color: #b62d20 !important;
  letter-spacing: 0;
  margin-bottom: 15px;
}
.information_mn p {
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  color: #000000;
  line-height: 32px;
  padding-top: 0;
  padding-bottom: 40px;
}
.contact_mn2 {
  width: 100%;
  float: left;
  padding: 0px;
  background: url(/src/Components/Image/petn.jpg);
  background-repeat: repeat-y;
  background-size: 100%;
  background-attachment: fixed;
  background-position: center;
  padding: 110px 0 20px 0;
}
.conatact-single-box {
  padding: 1px 45px 10px;
  background: #f5f5f5;
  text-align: center;
  margin-bottom: 60px;
  min-height: 190px;
  box-shadow: 0 0 15px #d2d2d2;
}
.contacts-icon {
  position: relative;
  top: -35px;
  background: #c1272d;
  display: inline-block;
  padding: 15px 30px;
  color: #fff;
  border-radius: 5px;
  font-size: 30px;
}
.contacts-section {
  width: 100%;
  float: left;
}
.contact-title h5 {
  font-size: 24px;
  color: #c1272d;
  font-weight: bold;
  text-transform: uppercase;
}
.contact-description p {
  font-size: 18px;
  line-height: 27px;
}
.map_mn {
  width: 100%;
  float: left;
  padding: 20px 0 60px 0;
}
.map {
  width: 100%;
  height: 500px;
}
/* marquee div container */
.marquee {
  font-size: 18px;
  color: #fff;
  font-family: "Work Sans", sans-serif;
  height: 2.5vw !important;
  overflow: hidden;
  background-color: #b62d20;
  position: fixed;
  z-index: 1;
  width: 100%;
  float: left;
  margin-top: 120px;
  margin-bottom: 0px;
  line-height: 38px;
}
/* nested div inside the container */
.marquee div {
  display: block;
  width: 200%;
  position: absolute;
  overflow: hidden;
  animation: marquee 18s linear infinite;
}
/* span with text */
.marquee span {
  float: left;
  line-height: 36px;
}
/* keyframe */
@keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
.line3 {
  height: 4px;
  border: solid 4px #b62d20;
  opacity: inherit;
  background: #b62d20;
  width: 12%;
  margin: 0 auto;
  margin-bottom: 15px;
  margin-top: 20px;
}
.tample_box h2 {
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  color: #fff;
  letter-spacing: 0;
  margin: 0;
  text-align: center;
  margin-bottom: 60px;
  margin-top: 40px;
}
.footer_tx h4 {
  color: #fff;
  font-size: 16px;
}
.logo_mob {
}
.logo_mob {
  width: 69px;
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
}
.logo_mob {
  width: 69px;
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
  display: none;
}
.tample_mn2 {
  width: 100%;
  float: left;
  padding: 140px 0;
  background: url(/src/Components/Image/petn.jpg);
  background-repeat: repeat-y;
  background-size: 100%;
  background-attachment: fixed;
  background-position: center;
}
.dru_clr {
  padding: 10px 20px !important;
  height: 47px !important;
  font-size: 17px !important;
  display: grid;
  align-items: center;
  background-color: #fff;
  color: #000;
  border-top: 1px solid #414040;
  margin: 70px !important;
}
.navbar-toggler {
  background: #b62d20 !important;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none !important;
}
.navbar-toggler-icon {
  filter: contrast(0) brightness(0) invert(1) !important;
}
.pic_wd {
  width: 90%;
  float: right;
}
.top-to-btm {
  position: relative;
}
.icon-position {
  position: fixed;
  bottom: 40px;
  right: 7px;
  z-index: 20;
}
.icon-style {
  background-color: #b62d20;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  color: #fff;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.icon-style:hover {
  animation: none;
  color: white;
  border: 2px solid #551b54;
}
.icon-position1 {
  position: fixed;
  bottom: 70px;
  right: 7px;
  z-index: 20;
}
.icon-style22 {
  background-color: #b62d20;
  border: 2px solid #fff;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.img_model33 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 61%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}
@keyframes movebtn {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(20px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}
.cir_sml {
  font-size: 18px !important;
  margin: 13px 0px 0 0;
  font-weight: bold;
}
.no_padding {
  padding: 0 !important;
  text-align: justify;
}
.box2 {
  border: 1px solid #b6b5b5;
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 40px 0;
  width: 90%;
  margin: 0 auto;
  background: #fff;
  text-align: center;
}
.box2 h1 {
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  color: #b62d20 !important;
  letter-spacing: 0;
  margin: 0;
}
.box2 h4 {
}
.box2 h5 {
  margin-bottom: 40px;
  margin-top: 20px;
}
.box2 p {
  padding: 3px 40px !important;
}
.reach_mn {
  width: 100% !important;
  text-align: center;
  float: left;
  padding: 40px 0;
}
.reach_mn h2 {
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  color: #b62d20 !important;
  letter-spacing: 0;
  margin: 0;
}
.conatact-single-box2 {
  padding: 0px 5px 10px;
  background: #f5f5f5;
  text-align: center;
  margin-bottom: 60px;
  min-height: 301px;
  box-shadow: 0 0 15px #d2d2d2;
  margin-top: 75px;
}
.contacts-icon img {
  width: 40px;
}
.gallery_mn {
  width: 100%;
  float: left;
}
.gallery_mn img {
  border: 1px solid red;
  width: 100%;
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-top: 40px;
  margin-bottom: 0px;
  height: 389px;
}
.gallery_mn_top {
  width: 100%;
  float: left;
  padding: 20px 0 70px 0;
  background: url(/src/Components/Image/petn.jpg);
  background-repeat: repeat-y;
  background-size: 100%;
  background-attachment: fixed;
  background-position: center;
}
.trust_tx {
  width: 100%;
  float: left;
  padding: 10px 0 20px 0;
}
.trust_tx h3 {
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  color: #b62d20 !important;
  letter-spacing: 0;
  margin: 0;
  text-align: center;
}
.dis_non {
  display: none !important;
}
.line4 {
  display: none;
}
.ruleImg {
  width: 100%;
  height: 570px !important;
}
.bc_clr {
  background-color: rgba(0, 0, 0, 0.8);
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
  padding: 0 !important;
  margin: 0;
}
.modl_wd {
  max-width: 1000px !important;
  height: auto !important;
  margin-top: 110px !important;
}
.modl_wd img {
  height: 600px !important;
}
.drp_glery {
}
.drp_glery a {
  margin-top: 0 !important;
}
.drp_glery {
  margin-top: 0 !important;
  line-height: 45px;
  text-align: left !important;
  transition: 0.4s;
}
.drp_glery:hover {
  background-color: #b62d20 !important;
  color: #fff !important;
}
.navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute;
  top: 66px;
}
.mrj_mn {
  width: 100%;
  float: left;
  padding: 60px 0 0 0;
}
/* dropdown */
#nav-dropdown32 {
  height: 99px !important;
  width: 16px !important;
  border: none;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
select option {
  font-weight: 600 !important;
  text-transform: uppercase !important;
}
#dropdown-products {
  display: none;
}
#menu-products:hover #dropdown-products {
  display: block;
}
.post_btn {
  background: #b62d20;
  color: #fff;
  outline: none;
  display: block;
  border: none;
  padding: 13px 36px;
  border-radius: 15px;
  width: 24% !important;
  margin: 0 auto;
  margin-top: 48px !important;
  font-size: 18px;
}
/* login css 06-7-24 */
.card-header {
  background: #b62d20 !important;
}
.btn_tx {
  display: inline-flex;
  width: 100%;
}
.btn_tx p {
  font-size: 24px;
  width: 100%;
  padding: 0;
  margin: 0;
  color: #fff;
}
.add_event_btn {
  padding: 0 !important;
  width: 14%;
  margin: 0;
  border: solid 1px #bfbfbf !important;
  background: #9f0101 !important;
  box-shadow: 0 5px 10px #29272759;
}
.btn_form_mn {
  text-align: center;
  padding: 25px 0;
}
.sv_btn {
  background: #b62d20 !important;
  color: #fff !important;
  padding: 8px 53px !important;
  outline: none !important;
  border: none !important;
  border-radius: 4px !important;
  margin: 0 10px !important;
}
.cn_btn {
  background: #6c757d;
  color: #fff;
  padding: 8px 53px;
  outline: none;
  border: none;
  border-radius: 4px;
}
.hKpKmf {
  width: 100%;
  background: url(/src/Components/Image/menu_bg.jpg) !important;
  background-repeat: no-repeat;
  background-size: cover !important;
}
.jtxsLV:hover {
  background: rgb(37, 40, 49);
  border-left: 4px solid white !important;
  cursor: pointer;
}
.img_list_gallery {
  margin: 8px !important;
  width: 78% !important;
  height: 197px;
  background: #727272;
  padding: 2px;
  background-size: 100% 100%;
  border-radius: 5px;
}
.dlt_icn {
  width: 27px;
  color: #fff;
  position: absolute;
  margin-top: 10px;
  background: #b62d20;
  padding: 4px;
  border-radius: 2px;
  cursor: pointer;
}
.fom_ht {
  height: 150px;
}
.galer_bg_clr {
  border: 1px solid #b6b5b5;
  border-radius: 15px;
  box-shadow: 0 5px 10px #00000059;
  margin-bottom: 20px;
  transform: translateZ(0);
  transition: all 0.3s linear;
  width: 100%;
  padding: 20px 20px 40px 40px;
}
.galer_bg_clr h4 {
  color: #b62d20;
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 17px;
}
.hd_tx_top {
  margin-left: 189px;
  color: orange;
  text-align: center;
  width: 100%;
}
.hd_tx_top22 {
  display: inline-flex;
  margin-right: 18px;
  color: orange;
  float: right;
  text-align: right;
  width: 17%;
  margin-top: 10px;
  font-size: 17px;
}
.hkxpIB {
  background: rgb(21, 23, 28);
  height: 65px !important;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
}
.modal-header {
  padding: 12px 15px !important;
}
.count_number {
  font-size: 22px;
  margin-top: -4px;
  margin-left: 3px;
}
.bg_login {
  background: url(/src/Components/Image/login_bg.jpg) left top no-repeat !important;
  background-size: cover !important;
}
.logn_mn1 {
  text-align: center !important;
}
.btn_top1 {
  margin-top: 20px !important;
}
.fon_two {
  width: 61%;
  margin: 0 auto;
}
label + .css-1eed5fa-MuiInputBase-root-MuiInput-root {
  margin-top: 16px;
  width: 207px !important;
}
.dis_bkl1 {
  display: block !important;
}
.loading-container {
  position: fixed;
  /* bottom: 14px; */
  top: 220px;
  right: 50%;
  float: right;
  z-index: 60;
  left: 47%;
  text-align: center;
}
.css-levciy-MuiTablePagination-displayedRows{ margin: 0px !important;}
.dissbb {
  margin-right: 8px !important;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important; 
}
.css-n3fyjk-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainerContent{ font-size: 15px !important;}
.hheLLF:hover {
  background: rgb(37, 40, 49);
  border-left: 4px solid #b62d20 !important;
  cursor: pointer;
}
.faq_section {
  width: 82% !important;
  margin: 0 auto;
}
.accordion-item:first-of-type>.accordion-header .accordion-button {
  background: beige !important;
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.que1{
  font-size: 20px !important;
  margin: 0px !important;
}
.answer_list{
  text-align: left !important;
}
/* css  according */
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700&display=swap");
body {
    background: #fafafa;
}
.accordion {
    display: flex;
    flex-direction: column;
    font-family: "Sora", sans-serif;
    max-width: 991px;
    min-width: 320px;
    margin: 50px auto;
    padding: 0 50px;
}
.accordion h1 {
    font-size: 32px;
    text-align: center;
}
.accordion-item {
    margin-top: 16px;
    border: 1px solid #fcfcfc;
    border-radius: 6px;
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}
.accordion-item .accordion-item-title {
    position: relative;
    margin: 0;
    display: flex;
    width: 100%;
    font-size: 15px;
    cursor: pointer;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 14px 20px;
    box-sizing: border-box;
    align-items: center;
    box-shadow: 0 0 15px #d2d2d2;
    border-left: solid 4px #b62d20;
    border-radius: 5px;
    font-weight: 600;
}
.accordion-item .accordion-item-desc {
  display: none;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #000;
  border-top: 1px dashed #ddd;
  padding: 10px 20px 20px;
  box-sizing: border-box;
  text-align: left;
  box-shadow: 0 0 15px #e5e5e5;
  background: #e9e9e9;
  border-radius: 5px;
  word-wrap: break-word !important;
}
.accordion-item input[type="checkbox"] {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
}
.accordion-item input[type="checkbox"]:checked ~ .accordion-item-desc {
    display: block;
}
.accordion-item
    input[type="checkbox"]:checked
    ~ .accordion-item-title
    .icon:after {
    content: "-";
    font-size: 20px;
}
.accordion-item input[type="checkbox"] ~ .accordion-item-title .icon:after {
    content: "+";
    font-size: 20px;
}
.accordion-item:first-child {
    margin-top: 0;
}
.accordion-item .icon {
  margin-left: 14px;
  border: solid 2px #b62d20;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  line-height: 22px;
  color: #b62d20;
}
@media screen and (max-width: 767px) {
    .accordion {
        padding: 0 16px;
    }
    .accordion h1 {
        font-size: 22px;
    }
}
.img_blur {
  filter: blur(3px) !important; 
}
.best_view{
  color: white;
  float: right;
}
.timer {
  font-size: 14px;
  margin: 3px 0 0;
}

.timer p{ display: inline-block;}

.resend-otp {
  margin: 0 0 0 17px;
  color: blue;
  cursor: pointer;
  display: inline-block;
}
.new_imag {
  position: relative;
  margin-top: -818px;
  width: 100px;
}
.latest_img {
  margin-left: -70px;
}
.timer_section{

}

.otp-input {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.otp-input input {
  width: 50px;
  height: 50px;
  margin: 0 8px;
  text-align: center;
  font-size: 1.5rem;
  border-radius: 12px;
  background-color: rgb(218 218 218 / 80%);
  color: #000;
  transition: all 0.3s ease;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  outline: none !important;
  font-weight: 600;
  border: none;
}
.container__slider {
  position: relative;
  width: 100%;
  padding: 0;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.container__slider > button {
  background-color: transparent;
  outline: none;
  border: 0;
  color: red;
  font-size: 24px;
  z-index: 2; /* Ensure buttons are above slides */
}

.container__slider > button:active {
  color: black;
}

.slider__btn-next {
  position: absolute;
  right: 10px; /* Add some padding from the edge */
}

.slider__btn-prev {
  position: absolute;
  left: 10px; /* Add some padding from the edge */
}

.container__slider__links {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  display: flex; /* Ensure dots are aligned in a row */
}

.container__slider__links-small {
  width: 8px;
  height: 8px;
  padding: 0;
  border-radius: 50%;
  background-color: white;
  border: 1px solid grey;
  transition: background-color 0.3s ease; /* Adjusted for faster feedback */
  margin: 0 2px;
}

.container__slider__links-small-active {
  background-color: black;
}

.slider__item {
  min-width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 1s ease;
}

.slider__item img {
  width: 100%;
  height: auto;
}

/* Generic active class to handle dynamic number of slides */
.slider__item-active {
  transform: translateX(calc(-100% * var(--active-index)));
}

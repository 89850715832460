
@media (max-width: 1280px){
    .container{ max-width: inherit; width: 98% !important;}

    .container, .container-lg, .container-md, .container-sm {
        max-width: inherit !important;
    }
}




@media screen and (max-width:1920px) and (min-width:1500px) {

    .marquee span {
        float: left;
        line-height: 41px;
    }

}

@media (max-width : 11280px) {



}


@media (max-width : 1180px) {

    .marquee {
        font-size: 18px;
        color: #fff;
        font-family: "Work Sans", sans-serif;
        height: 3.5vw !important;
        overflow: hidden;
        background-color: #b62d20;
        position: fixed;
        z-index: 1;
        width: 100%;
        float: left;
        margin-top: 120px;
        margin-bottom: 0px;
        line-height: 38px;
    }



    .tample_mn2 {
        width: 100%;
        float: left;
        padding: 41px 0;
        /* background: url(http://localhost:3000/kainchidham/static/media/petn.ede4d27….jpg); */
        background-repeat: repeat-y;
        background-size: 100%;
        background-attachment: fixed;
        background-position: center;
    }

    .fon_two {
        width: 100%;
        margin: 0 auto;
    }

    .faq_section {
        width: 97% !important;
        margin: 0 auto;
    }

    .accordion-item .accordion-item-title {
        position: relative;
        margin: 0;
        display: flex;
        width: 100%;
        font-size: 14px;
        cursor: pointer;
        justify-content: space-between;
        flex-direction: row-reverse;
        padding: 14px 20px;
        box-sizing: border-box;
        align-items: center;
        box-shadow: 0 0 15px #d2d2d2;
        border-left: solid 4px #b62d20;
        border-radius: 5px;
        font-weight: 600;
        text-align: left;
    }

    .accordion-item .icon {
        margin-left: 14px;
        border: solid 2px #b62d20;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        line-height: 13px;
        color: #b62d20;
        text-align: center;
        padding: 5px;
    }

    .hd_tx_top22 {
        display: inline-flex;
        margin-right: 18px;
        color: orange;
        float: right;
        text-align: right;
        width: 23%;
        margin-top: 10px;
        font-size: 17px;
    }

}
@media (max-width : 768px) {


    .reach_mn {
        width: 100% !important;
        text-align: center;
        float: left;
        padding: 0px 0;
    }

    .latest_img {
        margin-left: 0;
        width: 100%;
    }

    .otp-input input {
        width: 37px;
        height: 37px;
        margin: 0 5px;
        text-align: center;
        font-size: 19px;
        border-radius: 12px;
        background-color: rgb(218 218 218 / 80%);
        color: #000;
        transition: all 0.3s ease;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
        outline: none !important;
        font-weight: 600;
        border: none;
    }

    

    .timer {
        display: inline-flex;
        font-size: 14px;
        margin: 3px 0 0;
        width: auto;
        padding-left: 0;
    }

    .new_imag {
        position: absolute;
        margin-top: -39px;
        left: 0;
        width: 71px;
    }


    .count_number {
        font-size: 20px;
        margin-top: -4px;
        margin-left: 3px;
    }

    .contact_mn1 {
        width: 100%;
        float: left;
        padding: 20px 0 40px 0;
        background-position: center;
        background-size: cover;
    }

    .line4 {
        height: 4px;
        border: solid 4px #b62d20;
        opacity: inherit;
        background: #b62d20;
        width: 34% !important;
        margin: 0 auto;
        margin-bottom: 15px;
        margin-top: 20px !important;
        display: inherit !important;
    }

    .post_btn {
        background: #b62d20;
        color: #fff;
        outline: none;
        display: block;
        border: none;
        padding: 12px 28px;
        border-radius: 15px;
        width: 66% !important;
        margin: 0 auto;
        margin-top: 26px !important;
        font-size: 16px;
    }

    .modl_wd {
        max-width: 1000px !important;
        height: auto !important;
        margin-top: 87px !important;
    }

    .form_mn {
        width: 94%;
        margin: 0 auto;
    }

    .add_event_btn {
        padding: 0 !important;
        width: 95%;
        margin: 0;
        border: solid 1px #bfbfbf !important;
        background: #9f0101 !important;
        box-shadow: 0 5px 10px #29272759;
    }

    .hd_tx_top {
        font-size: 22px;
        margin-left: 0px;
        margin-right: 0px;
        color: orange;
        float: right;
        text-align: right;
        width: 100%;
        margin-bottom: 0;
    }

    .hd_tx_top22 {
        color: orange;
        float: right;
        text-align: right;
        width: 100%;
        padding: 0;
        margin: 22px 0px 0 20px;
        font-size: 14px;
    }

    .form_mn h3 {
        color: #fff;
        margin-bottom: 15px;
        font-size: 30px;
    }

    .modal-header{padding: 11px 10px !important;}

    .sv_btn {
        background: #b62d20;
        color: #fff;
        padding: 6px 40px;
        outline: none;
        border: none;
        border-radius: 4px;
        margin: 0 10px;
    }

    .cn_btn {
        background: #6c757d;
        color: #fff;
        padding: 6px 40px;
        outline: none;
        border: none;
        border-radius: 4px;
    }

    .img_list_gallery {
        margin: 8px !important;
        width: 86% !important;
        height: 194px;
        background: #727272;
        padding: 2px;
        background-size: 100% 100%;
        border-radius: 2px;
        position: relative;
    }

    .galer_bg_clr {
        border: 1px solid #b6b5b5;
        border-radius: 15px;
        box-shadow: 0 5px 10px #00000059;
        margin-bottom: 20px;
        transform: translateZ(0);
        transition: all .3s linear;
        width: 98%;
        padding: 20px 20px 6px 17px;
    }

    .line3 {
        height: 4px;
        border: solid 4px #b62d20;
        opacity: inherit;
        background: #b62d20;
        width: 34%;
        margin: 0 auto;
        margin-bottom: 45px;
        margin-top: 20px;
    }


    .gallery_mn_top{ width: 100%; float: left; padding: 0px 0 60px 0;
        background: url(/src/Components/Image/petn.jpg);
        background-repeat: repeat-y; background-size: cover !important;
        background-attachment: fixed;
        background-position: center;}

        .gallery_mn img {
            width: 100%;
            border-radius: 15px;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            margin-top: 30px;
            margin-bottom: 0px;
        }

        .trust_tx {
            width: 100%;
            float: left;
            padding: 60px 0 30px 0;
        }


.disply_non{ display: none;}


.dis_non {
    display: inherit !important;
    margin-top: 45px !important;
    
}

        .container, .container-sm {
            max-width: 99% !important;
        }
 

    .ashrm_mn p {
        padding: 0 10px;
        line-height: 30px;
    } 


.notes_mn p {
    font-family: "Work Sans", sans-serif;
    font-weight: 400;
    color: #ffffff;
    font-size: 17px;
    letter-spacing: 0;
    line-height: 32px;
    padding: 0;
    text-align: justify;
}

.notes_mn {
    width: 100%;
    float: left;
    padding: 40px 10px;
}

.marquee {
    font-size: 18px;
    color: #fff;
    font-family: "Work Sans", sans-serif;
    height: 40px;
    overflow: hidden;
    background-color: #b62d20;
    position: fixed;
    z-index: 9;
    width: 100%;
    float: left;
    margin-top: 74px;
    margin-bottom: 0px;
}

.banner_mn {
    width: 100%;
    float: left;
    margin-top: 112px;
}

.tample_mn {
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0;
    background-size: 100% 100%;
}

.tample_size {
    background: #fff;
    text-align: center;
    border-radius: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-bottom: 20px;
    min-height: auto;
}

.tample_box {
    width: 100%;
    float: left;
    margin-bottom: 20px;
}

.tample_box h2 {
    font-family: "Work Sans", sans-serif;
    font-weight: 700;
    color: #fff;
    font-size: 26px;
    letter-spacing: 0;
    margin: 0;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 40px;
}

.contact_mn {
    width: 100%;
    float: left;
    padding: 60px 0;
    height: 155px;
}

.footer_tx2 {
    padding-left: 17px !important;
    margin-bottom: 15px;
}

.footer_mn {
    width: 100%;
    float: left;
    padding: 20px 0;
}

.footer_logo img {
    width: 30%;
    margin-bottom: 15px;
}

.nv_rit a {
    font-weight: 600;
    margin-top: 14px;
}

.me-auto {
    margin-right: auto !important;
    margin-top: 6px;
}

.karauli_maharaj_mn {
    width: 100%;
    float: left;
    padding: 30px 0 4px 0;
    text-align: center;
    margin-top: 66px;
}

.karauli_maharaj_mn p {
    font-family: "Work Sans", sans-serif;
    font-weight: 400;
    color: #fff;
    /* font-size: 22px; */
    text-align: center;
    line-height: 26px;
    padding: 0;
    padding-top: 14px;
}

.about_pic img {
    width: 100%;
    border-radius: 15px;
}

.sid_tx {
    font-size: 24px;
    background: #b62d20;
    color: #fff !important;
    padding: 20px !important;
    border-radius: 15px;
}

.about_tx h3 {
    font-family: "Work Sans", sans-serif;
    font-weight: 700;
    color: #b62d20 !important;
    /* font-size: 42px; */
    letter-spacing: 0;
    margin: 0;

}

.box1 {
    border: 1px solid #b6b5b5;
    border-radius: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 40px 0;
    width: 100%;
    margin: 0 auto;
    background: #fff;
}

.footer_tx li img {
    width: 40px;
}


.logo_mob {
    width: 76px;
    position: absolute;
    right: 0;
    top: 0;
    padding: 7px;
}

.header_fix1 {
    position: fixed !important;
    width: 100%;
    z-index: 99;
    padding: 18px 0 !important;
}

.logo_pic img {
    width: 60%;
    margin: 0 auto;
    border: solid 1px #3f3f3f;
    border-radius: 50%;
    display: none;
  }

  .size_class {
    padding: 14px 8px;
    padding-top: 0 !important;
    line-height: 0;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    height: 30px;
    border-radius: 5px;
    outline: none !important;
    margin-right: 10px !important;
    margin-top: 13px !important;
    background: #b62d20;
    color: #fff;
    border: none !important;
}

.logo_mob {
    width: 72px;
    position: absolute;
    right: 7px;
    top: 0;
    padding: 6px;
    display: inherit !important;
}

  .hit1 {
    height: auto;
}

.karauli_maharaj_mn h2 {
    font-family: "Segoe UI-Semibold", Helvetica;
    font-weight: bold;
    color: #fff;
    font-size: 24px;
    text-align: center;
    line-height: normal;
}

.tample_mn2 {
    width: 100%;
    float: left;
    padding: 40px 0;
    background-size: 100%;
    background-attachment: fixed;
    background-position: center;
}

.contact_mn{background-attachment: inherit;}

.drp_styl{ text-align: left; margin-left: 15px;}

.bg-body-tertiary{box-shadow: rgba(100, 100, 111, 0.2) 10px 7px 29px 20px !important;}

.about_tx p {
    font-family: "Work Sans", sans-serif;
    font-weight: 400;
    color: #000000;
    /* font-size: 22px; */
    line-height: 36px;
    padding-top: 15px;
}

.padding1{ padding: 0px !important;}

.about_pic {
    border: 1px solid #b6b5b5;
    border-radius: 15px;
    margin-bottom: 30px;
    width: 100%;
}

.about_section {
    width: 100%;
    float: left;
    padding: 20px 10px;
}

.information_mn {
    width: 100%;
    float: left;
    padding: 20px 0 0 0;
}

.contact_mn2 {
    width: 100%;
    float: left;
    padding: 0px;
    background-attachment: fixed;
    background-position: center;
    padding: 75px 0 0px 0;
    background: url(/src/Components/Image/petn.jpg);
    background-repeat: repeat-y; background-size: cover !important;
}

.tample_size a {
    background: #B62D20;
    color: #fff;
    padding: 8px 30px;
    position: inherit;
    bottom: 0;
    text-align: center;
    transform: inherit;
    margin-bottom: 20px;
}

.about_mn{padding: 0px 0 20px 0;}

.map {
    width: 100%;
    height: 300px;
}

.map_mn {
    width: 100%;
    float: left;
    padding: 0px 0 40px 0;
}

.ashrm_mn{ padding: 25px 0px;}

.navbar-toggler-icon {
    display: inline-block;
    width: 23px;
    height: 26px;
    vertical-align: middle;
    background-image: var(--bs-navbar-toggler-icon-bg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

}








@media (max-width : 640px) {

    .cTzPdH {
        margin-left: 1rem !important;
        font-size: 23px !important;
        height: 80px;
        display: flex;
        -webkit-box-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        align-items: center;
    }


    .ashrm_mn p {
        padding: 0 10px;
        line-height: 30px;
    } 


.notes_mn p {
    font-family: "Work Sans", sans-serif;
    font-weight: 400;
    color: #ffffff;
    font-size: 17px;
    letter-spacing: 0;
    line-height: 32px;
    padding: 0;
    text-align: justify;
}

.notes_mn {
    width: 100%;
    float: left;
    padding: 40px 10px;
}

.marquee {
    font-size: 18px;
    color: #fff;
    font-family: "Work Sans", sans-serif;
    height: 40px !important;
    overflow: hidden;
    background-color: #b62d20;
    position: fixed;
    z-index: 9;
    width: 100%;
    float: left;
    margin-top: 74px;
    margin-bottom: 0px;
}

.banner_mn {
    width: 100%;
    float: left;
    margin-top: 112px;
}

.tample_mn {
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0;
    background-size: 100% 100%;
    background: #dd7703;
}

.tample_size {
    background: #fff;
    text-align: center;
    border-radius: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-bottom: 20px;
    min-height: auto;
}

.tample_box {
    width: 100%;
    float: left;
    margin-bottom: 20px;
}

.tample_box h2 {
    font-family: "Work Sans", sans-serif;
    font-weight: 700;
    color: #fff;
    font-size: 26px;
    letter-spacing: 0;
    margin: 0;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 40px;
}

.contact_mn {
    width: 100%;
    float: left;
    padding: 60px 0;
    height: 155px;
}

.footer_tx2 {
    padding-left: 17px !important;
    margin-bottom: 15px;
}

.footer_mn {
    width: 100%;
    float: left;
    padding: 20px 0;
}

.footer_logo img {
    width: 30%;
    margin-bottom: 15px;
}

.nv_rit a {
    font-weight: 600;
    margin-top: 14px;
    text-align: left;
    color: #000;
}

.me-auto {
    margin-right: auto !important;
    margin-top: 6px;
}

.karauli_maharaj_mn {
    width: 100%;
    float: left;
    padding: 30px 0 4px 0;
    text-align: center;
    margin-top: 66px;
}

.karauli_maharaj_mn p {
    font-family: "Work Sans", sans-serif;
    font-weight: 400;
    color: #fff;
    /* font-size: 22px; */
    text-align: center;
    line-height: 26px;
    padding: 0;
    padding-top: 14px;
}

.about_pic img {
    width: 100%;
    border-radius: 15px;
}

.sid_tx {
    font-size: 24px;
    background: #b62d20;
    color: #fff !important;
    padding: 20px !important;
    border-radius: 15px;
}

.about_tx h3 {
    font-family: "Work Sans", sans-serif;
    font-weight: 700;
    color: #b62d20 !important;
    /* font-size: 42px; */
    letter-spacing: 0;
    margin: 0;

}

.box1 {
    border: 1px solid #b6b5b5;
    border-radius: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 40px 0;
    width: 100%;
    margin: 0 auto;
    background: #fff;
}

.footer_tx li img {
    width: 40px;
}


.logo_mob {
    width: 76px;
    position: absolute;
    right: 0;
    top: 0;
    padding: 7px;
}

.header_fix1 {
    position: fixed !important;
    width: 100%;
    z-index: 99;
    padding: 18px 0 !important;
}

.logo_pic img {
    width: 60%;
    margin: 0 auto;
    border: solid 1px #3f3f3f;
    border-radius: 50%;
    display: none;
  }

  .size_class {
    padding: 14px 8px;
    padding-top: 0 !important;
    line-height: 0;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    height: 30px;
    border-radius: 5px;
    outline: none !important;
    margin-right: 10px !important;
    margin-top: 13px !important;
    background: #b62d20;
    color: #fff;
    border: none !important;
}

.logo_mob {
    width: 72px;
    position: absolute;
    right: 7px;
    top: 0;
    padding: 6px;
    display: inherit !important;
}

  .hit1 {
    height: auto;
}

.karauli_maharaj_mn h2 {
    font-family: "Segoe UI-Semibold", Helvetica;
    font-weight: bold;
    color: #fff;
    font-size: 24px;
    text-align: center;
    line-height: normal;
}

.tample_mn2 {
    width: 100%;
    float: left;
    padding: 40px 0;
    background-size: 100%;
    background-attachment: fixed;
    background-position: center;
}

.contact_mn{background-attachment: inherit;}

.drp_styl{ text-align: left; margin-left: 15px;}

.bg-body-tertiary{box-shadow: rgba(100, 100, 111, 0.2) 10px 7px 29px 20px !important;}

.about_tx p {
    font-family: "Work Sans", sans-serif;
    font-weight: 400;
    color: #000000;
    /* font-size: 22px; */
    line-height: 36px;
    padding-top: 15px;
    text-align: justify;
}

.padding1{ padding: 0px !important;}

.about_pic {
    border: 1px solid #b6b5b5;
    border-radius: 15px;
    margin-bottom: 30px;
    width: 100%;
}

.about_section {
    width: 100%;
    float: left;
    padding: 20px 10px;
}

.information_mn {
    width: 100%;
    float: left;
    padding: 20px 0 0 0;
}

.contact_mn2 {
    width: 100%;
    float: left;
    padding: 0px;
    background-repeat: repeat-y;
    background-size: 100%;
    background-attachment: fixed;
    background-position: center;
    padding: 75px 0 0px 0;
}

.tample_size a {
    background: #B62D20;
    color: #fff;
    padding: 8px 30px;
    position: inherit;
    bottom: 0;
    text-align: center;
    transform: inherit;
    margin-bottom: 20px;
}

.about_mn{padding: 0px 0 20px 0;
    width: 100%; float: left;
    background: url(/src/Components/Image/petn.jpg);
    background-repeat: repeat-y; background-size: cover!important;
    background-attachment: fixed;
    background-position: center;}

.map {
    width: 100%;
    height: 300px;
}

.map_mn {
    width: 100%;
    float: left;
    padding: 0px 0 40px 0;
}

.ashrm_mn{ padding: 25px 0px;}

.navbar-toggler-icon {
    display: inline-block;
    width: 23px !important;
    height: 26px !important;
    vertical-align: middle;
    background-image: var(--bs-navbar-toggler-icon-bg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}


.conatact-single-box2 {
    padding: 30px 5px 10px;
    background: #f5f5f5;
    text-align: center;
    margin-bottom: 40px;
    min-height: 255px;
    box-shadow: 0 0 15px #d2d2d2;
    margin-top: 0px;
}

.box2 p {
    padding: 3px 5px !important;
}

}


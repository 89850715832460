.logo_img{
    margin-left: 10px;
    margin-bottom: 47px;
    width: 110px;
    margin-top: 11px;
}

.form-control {
    /* width: 56% !important; */
    display: block;
    padding: 0.375rem 0.75rem;
    /* font-size: 1rem; */
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


 ul li{
    list-style: none !important;

}

